import { parseTemplateString } from '@/helpers/utils';
import { notFound } from '@text';

export const NotFoundMessage = () => {
  const { env, app, service, revision } = useParams();
  let key: keyof typeof notFound = 'general';
  let name: string | undefined;

  if (app) {
    key = 'app';
    name = app;
  }
  if (env) {
    key = 'env';
    name = env;
  }
  if (service) {
    key = 'service';
    name = service;
  }
  if (revision) {
    key = 'revision';
    name = revision;
  }
  const { title, content } = notFound[key] as {
    title: string;
    content: string;
  };
  return (
    <>
      <h1>{parseTemplateString(title, { name })}</h1>
      <p>{parseTemplateString(content, { name })}</p>
    </>
  );
};
