import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import ApiClientProvider from '@/providers/ApiClient';
import MttsApiClientProvider from '@/providers/MttsApiClient';
import UserProvider from '@/providers/User';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import routes from './routes';
import { AuthInProgress } from './components/AuthInProgress/AuthInProgress';
import { PipelineProvider } from './providers/PipelineProvider';

function App() {
  const router = createBrowserRouter(routes);
  const { error } = useMsalAuthentication(InteractionType.Redirect);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (import.meta.env.DEV && error) {
      console.error(error);
    }
  }, [error]);
  if (!isAuthenticated && inProgress !== InteractionStatus.HandleRedirect) {
    return <AuthInProgress />;
  }

  return (
    <AuthenticatedTemplate>
      <MttsApiClientProvider>
        <ApiClientProvider>
          <UserProvider>
            <PipelineProvider>
              <RouterProvider router={router}></RouterProvider>
            </PipelineProvider>
          </UserProvider>
        </ApiClientProvider>
      </MttsApiClientProvider>
    </AuthenticatedTemplate>
  );
}

export default App;
