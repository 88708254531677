import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MdsConfig } from '@maersk-global/mds-config';
import * as dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

import '@maersk-global/fonts/maeu/fonts.css';
import '@maersk-global/mds-foundations/css/_reset.css';
import '@maersk-global/mds-foundations/css/_normalize.css';
import '@maersk-global/mds-foundations/css/_horizonal-rule.css';
import '@maersk-global/mds-foundations/css/_svg.css';
import 'text-security/text-security-disc.css';

import { SWRConfig } from 'swr';
import { swrErrorHandler } from '@/helpers/swr';
import ErrorProvider from '@/providers/Error';
import NotFoundProvider from '@/providers/NotFound';

import '@scss/index.scss';
import ThemeProvider from '@/providers/ThemeProvider';
import { loadTheme } from '@/helpers/theme';
import initRUM from '@/helpers/rum';
import GrowthBook from './providers/GrowthbookProvider';
import { ToastProvider } from './providers/ToastProvider';

void loadTheme();

initRUM();

dayjs.extend(durationPlugin);
dayjs.extend(advancedFormat);
dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);
dayjs.extend(relativeTime);

MdsConfig.iconsDynamicImportPath = '../../';

if (import.meta.env.PROD) {
  // If your production path differs from development
  MdsConfig.iconsDynamicImportPath = './';
}

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: window.envVars.VITE_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${window.envVars.VITE_AZURE_TENANT_ID}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ToastProvider>
      <MsalProvider instance={msalInstance}>
        <GrowthBook>
          <ThemeProvider>
            <NotFoundProvider>
              <ErrorProvider>
                <SWRConfig
                  value={{
                    use: [swrErrorHandler],
                    errorRetryCount: 10,
                    onErrorRetry: (
                      error,
                      key,
                      config,
                      revalidate,
                      { retryCount },
                    ) => {
                      if (retryCount >= 10) return;
                      if (error.response?.status === 404) return;
                      if (error.response?.status === 401) return;
                      setTimeout(() => revalidate({ retryCount }), 4000);
                    },
                  }}
                >
                  <App />
                </SWRConfig>
              </ErrorProvider>
            </NotFoundProvider>
          </ThemeProvider>
        </GrowthBook>
      </MsalProvider>
    </ToastProvider>
  </React.StrictMode>,
);
