import { Suspense } from 'react';
import DefaultLayout from '@/layouts/Default/Default';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';

const AppDashboardPage = lazy(
  () => import('@/pages/AppDashboard/AppDashboard'),
);
const AppPage = lazy(() => import('@/pages/App/App'));
const ServicePage = lazy(() => import('@/pages/Service/Service'));
const NotFoundPage = lazy(() => import('@/pages/NotFound/NotFound'));
const RevisionPage = lazy(() => import('@/pages/Revision/Revision'));
const PipelinePage = lazy(() => import('@/pages/Pipeline/Pipeline'));
const TeamsPage = lazy(() => import('@/pages/Teams/Teams'));
const TeamPage = lazy(() => import('@/pages/Team/Team'));
const NewSecretGroupPage = lazy(
  () => import('@/pages/NewSecretGroup/NewSecretGroup'),
);
const SecretGroupPage = lazy(() => import('@/pages/SecretGroup/SecretGroup'));
const NewBlankServicePage = lazy(
  () => import('@/pages/NewBlankService/NewBlankService'),
);
const CopyServicePage = lazy(() => import('@/pages/CopyService/CopyService'));
const CopySecretGroupPage = lazy(
  () => import('@/pages/CopySecretGroup/CopySecretGroup'),
);

export default [
  {
    path: '/',
    element: <DefaultLayout />,
    errorElement: (
      <DefaultLayout>
        <ErrorBoundary />
      </DefaultLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <AppDashboardPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'my-apps',
          };
        },
      },
      {
        path: '/my-apps',
        element: (
          <Suspense>
            <AppDashboardPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'my-apps',
          };
        },
      },
      {
        path: '/all-apps',
        element: (
          <Suspense>
            <AppDashboardPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'all-apps',
          };
        },
      },
      {
        path: '/team',
        element: (
          <Suspense>
            <TeamsPage />
          </Suspense>
        ),
        loader: () => {
          return { tab: 'my-teams' };
        },
      },
      {
        path: '/my-teams',
        element: (
          <Suspense>
            <TeamsPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'my-teams',
          };
        },
      },
      {
        path: '/all-teams',
        element: (
          <Suspense>
            <TeamsPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'all-teams',
          };
        },
      },
      {
        path: 'team/:team',
        element: (
          <Suspense>
            <TeamPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/revision/:revision',
        element: (
          <Suspense>
            <RevisionPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/pipeline/:pipeline',
        element: (
          <Suspense>
            <PipelinePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/:tab',
        element: (
          <Suspense>
            <ServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/copy-service',
        element: (
          <Suspense>
            <CopyServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/',
        element: (
          <Suspense>
            <ServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'service',
          };
        },
      },
      {
        path: 'app/:app/:env/secret-group/:secretGroup',
        element: (
          <Suspense>
            <SecretGroupPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/secret-group/:secretGroup/copy',
        element: (
          <Suspense>
            <CopySecretGroupPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/secret-group',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'secret-group',
          };
        },
      },
      {
        path: 'app/:app/:env/new-secret-group',
        element: (
          <Suspense>
            <NewSecretGroupPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/new-service',
        element: (
          <Suspense>
            <NewBlankServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/activity-logs',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'activity-logs',
          };
        },
      },
      {
        path: 'app/:app/:env',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'service',
          };
        },
      },
      {
        path: 'app/:app',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'service',
          };
        },
      },
      {
        path: '*',
        element: (
          <Suspense>
            <NotFoundPage />
          </Suspense>
        ),
      },
    ],
  },
];
