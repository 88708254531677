import { ReactNode, useEffect } from 'react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import growthbook from '@/helpers/growthbook';

const GrowthBook = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    growthbook.loadFeatures().catch((err) => {
      console.error('gb failed to load', err);
    });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default GrowthBook;
